import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

const imgStyles = {
    objectFit: "cover",
    filter: "brightness(50%)"
};

const LandingSlide = () => {
    return (
        <>
            <StaticImage
                className="w-100 mw-100 h-100 d-block"
                style={imgStyles}
                src="../../images/first_slide.jpg"
                alt="First slide"/>
            {/*<h1 className="centered text-center text-white">Welcome to BDM Building Projects</h1>*/}
        </>
        // <Flickity
        //     className={"carousel-landing"}
        //     elementType={"div"}
        //     options={flickityOptions}
        //     disableImagesLoaded={false}
        //     reloadOnUpdate
        //     static>
        //     <img
        //         className="carousel-cell-landing d-block w-100 h-100"
        //         style={imgStyles}
        //         src={FirstSlide}
        //         alt="First slide"/>
        //     <img
        //         className="carousel-cell-landing d-block w-100 h-100"
        //         style={imgStyles}
        //         src={SecondSlide}
        //         alt="Second slide"/>
        //     <img
        //         className="carousel-cell-landing d-block w-100 h-100"
        //         style={imgStyles}
        //         src={ThirdSlide}
        //         alt="Third slide"/>
        // </Flickity>
    );
}

export default LandingSlide;